import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useSendDataToParentIframeHandler } from 'containers/FormSwitcher/utils';

import SecondaryNavWrapper from 'common/components/SecondaryNavWrapper';
import Stepper from 'common/components/Stepper';
import ErrorMessage from 'common/components/ErrorMessage';
import { DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION, VIEWS_LIST } from './constants';
import { IStepView } from './types';

import styles from './styles.module.scss';
import { scrollToEmptyInput } from '../../utils/scrollToInput';
import useSearchQuery from '../../utils/useSearchQuery';
import { wrapScrollData, wrapSizeData } from '../../api/cart/utils';


const resizeObserver = new ResizeObserver((entries) => {
  [...entries].forEach((entry) => {
    if (entry.contentBoxSize) {
      const contentBoxSize = entry.contentBoxSize[0];

      window.parent.postMessage(wrapSizeData({
        width: contentBoxSize.inlineSize,
        height: contentBoxSize.blockSize,
      }), '*');

      console.log('Size changed 1', {
        width: contentBoxSize.inlineSize,
        height: contentBoxSize.blockSize,
      });
    } else {
      window.parent.postMessage(wrapSizeData({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      }), '*');

      console.log('Size changed 2', {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    }
  });
});


const FormSwitcher = () => {
  const searchParams = useSearchQuery();
  const preselectedHoseQuery = searchParams.hose;

  const { fields, isLoading } = useGenericFieldsData();
  const [viewStep, setViewStep] = useState<number>(preselectedHoseQuery ? 1 : 0);
  const [stepIndexes, setStepIndexes] = useState<Array<number>>(VIEWS_LIST.map((el, index) => index));
  const [error, setError] = useState<string | undefined>();
  const [isCartLoading, setIsCartLoading] = useState<boolean>(false);
  const navigationEl = useRef<HTMLDivElement>(null);
  const [navigationTop, setNavigationTop] = useState<number>(0);


  const preselectedHoseProduct = fields.hoseProduct?.value?.selected;
  const [hasProcessedPreselection, setHasProcessedPreselection] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && !hasProcessedPreselection) {
      setHasProcessedPreselection(true);
      if (preselectedHoseQuery) {
        if (preselectedHoseProduct) {
          setViewStep(1);
        } else {
          setViewStep(0);
        }
      }
    }
  }, [isLoading, preselectedHoseQuery, preselectedHoseProduct, hasProcessedPreselection]);

  const scrollToInput = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    const topOfApp = document.getElementById('root');
    clearTimeout(scrollToInput.current);
    scrollToInput.current = setTimeout(() => {
      if (!window.parent) {
        topOfApp!.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        window.parent.postMessage(wrapScrollData({
          type: 'top',
          top: 0,
          height: topOfApp!.getBoundingClientRect().height,
        }), '*');
      }
    }, 300);
  }, [viewStep]);

  useEffect(() => {
    clearTimeout(scrollToInput.current);
    scrollToInput.current = setTimeout(() => {
      scrollToEmptyInput();
    }, 300);
  }, [fields]);

  useEffect(() => {
    navigationEl.current && setNavigationTop(navigationEl.current.getBoundingClientRect().top);
  }, []);

  const handleScrollToMakeSticky = useCallback(() => {
    const scrollTop = window.scrollY;
    if (scrollTop > navigationTop) {
      navigationEl.current?.classList.add(styles.sticky);
    } else {
      navigationEl.current?.classList.remove(styles.sticky);
    }
  }, [navigationTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollToMakeSticky);
    return () => {
      window.removeEventListener('scroll', handleScrollToMakeSticky);
    };
  }, [handleScrollToMakeSticky]);

  const stepsSimplified = useMemo<Array<string>>(
    () => stepIndexes.map((viewIndex) => VIEWS_LIST[viewIndex].stepName),
    [stepIndexes],
  );

  const isLastStep = viewStep + 1 === VIEWS_LIST.length;

  const isNextEnabled = useMemo<boolean>(
    () => (isLastStep || !!stepIndexes.find((index) => viewStep < index)) && !error,
    [isLastStep, stepIndexes, error, viewStep],
  );

  const sendDataToParentIframeHandler = useSendDataToParentIframeHandler(setError, setIsCartLoading);

  const onNextClickHandler = useCallback(
    () => {
      if (viewStep + 1 < VIEWS_LIST.length) {
        let nextAvailableStep: IStepView | undefined;
        let index = viewStep + 1;
        const visibleViewsIndexes = stepIndexes.filter((viewIndex) => viewIndex < index);
        while (index < VIEWS_LIST.length) {
          const reqField = VIEWS_LIST[index].requiredFields;
          const isAvailableFieldExist = !!reqField.find((fieldName) => (
            !!fields[fieldName] && fields[fieldName]?.status !== 'disabled'
          ));
          if (isAvailableFieldExist || reqField.length === 0) {
            nextAvailableStep = VIEWS_LIST[index];
            visibleViewsIndexes.push(index);
            break;
          }
          index++;
        }
        const availableIndexesAfterCurrent = Array.from(
          { length: VIEWS_LIST.length - index - 1 },
          (el, ind) => ind + index + 1,
        );
        visibleViewsIndexes.push(...availableIndexesAfterCurrent);
        setStepIndexes(visibleViewsIndexes);
        if (!nextAvailableStep) {
          setError(DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION);
        } else {
          setError(undefined);
          setViewStep(index);
        }
      } else {
        setIsCartLoading(true);
        // Submit to cart
        sendDataToParentIframeHandler();
      }
    },
    [viewStep, stepIndexes, fields, sendDataToParentIframeHandler],
  );

  const onBackClickHandler = useCallback(
    () => {
      const previous = stepIndexes.findIndex((viewIndex) => viewIndex === viewStep);
      if (previous - 1 >= 0) {
        setError(undefined);
        setViewStep(stepIndexes[previous - 1]);
      }
    },
    [viewStep, setViewStep, stepIndexes],
  );

  const onResetHandler = useCallback(
    () => {
      setIsCartLoading(true);
      window.location.hash = 'new';
      window.location.reload();
    },
    [viewStep, setViewStep, stepIndexes],
  );

  const onStepChangeHandler = useCallback(
    (stepName: string) => {
      const step = VIEWS_LIST.findIndex((view) => view.stepName === stepName);
      if (step > viewStep) {
        return false;
      }
      setViewStep(step ?? viewStep);
      return true;
    },
    [viewStep, setViewStep],
  );

  const clearProgressError = useCallback(
    () => {
      if (error) {
        setError(undefined);
      }
    },
    [setError, error],
  );

  const handleSetNoFieldsAvailable = useCallback(
    () => setError(DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION),
    [setError],
  );

  const ViewComponent = VIEWS_LIST[viewStep].component;


  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resizeObserver.observe(containerRef.current!);

    window.parent.postMessage(wrapSizeData({
      width: containerRef.current!.getBoundingClientRect().width,
      height: containerRef.current!.getBoundingClientRect().height,
    }), '*');
  }, []);


  return (
    <div className={styles.wrapper}>
      <div ref={containerRef}>
        <div className={styles.wrapperInner}>
          <div
            ref={navigationEl}
            className={styles.navigation}
          >
            <Stepper
              onClickStepper={onStepChangeHandler}
              steps={stepsSimplified}
              step={VIEWS_LIST[viewStep].stepName}
            />
            <div className={styles.container}>
              <SecondaryNavWrapper />
            </div>
          </div>

          <div className={styles.container}>
            <ViewComponent
              title={VIEWS_LIST[viewStep].title}
              description={VIEWS_LIST[viewStep].description}
              key={VIEWS_LIST[viewStep].title}
              onNextClickHandler={onNextClickHandler}
              onBackClickHandler={onBackClickHandler}
              onStepChange={onStepChangeHandler}
              onResetHandler={onResetHandler}
              fieldsNames={VIEWS_LIST[viewStep].requiredFields}
              isNextDisabled={!isNextEnabled}
              clearProgressError={clearProgressError}
              isLoading={isCartLoading}
              handleSetNoFieldsAvailable={handleSetNoFieldsAvailable}
            >
              {error && (
                <ErrorMessage
                  text={error}
                  className={styles.commonError}
                />
              )}
            </ViewComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSwitcher;
