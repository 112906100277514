import { wrapScrollData } from '../api/cart/utils';

export const scrollToEmptyInput = () => {
  let maxTop = 10000;
  let element: Element | null = null;
  document.querySelectorAll('[data-field-wrapper][data-has-value="0"][data-ignore-scroll-to="0"],[data-submit-button]').forEach((el) => {
    if (el.getBoundingClientRect().top < maxTop && el.getBoundingClientRect().height) {
      maxTop = el.getBoundingClientRect().top;
      element = el;
    }
  });
  if (element) {
    if (!window.parent) {
      (element as Element).scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      const topOfElement = (element as Element).getBoundingClientRect().top + window.scrollY;
      window.parent.postMessage(wrapScrollData({
        type: 'center',
        top: topOfElement,
        height: (element as Element).getBoundingClientRect().height,
      }), '*');
    }
  }
};
