export enum CONNECTION_TYPES {
  AXIOS = 'axios',
}

export const DEFAULT_UNKNOWN_ERROR_MESSAGE: string = 'An unexpected error occurred. Please, try later.';
export const DEFAULT_UNKNOWN_FIELD_ERROR_MESSAGE: string = 'An occurred while field passing validation';
export const DEFAULT_UNKNOWN_ERROR_STATUS: number = 520;

export const DEFAULT_SERVER_ERROR_MESSAGE: string = 'An error occurred on the server. Please, try later.';

export const IFRAME_MESSAGE_TYPE = '@cpq';
export const IFRAME_MESSAGE_SIZE_TYPE = '@cpq:size';
export const IFRAME_MESSAGE_SCROLL_TYPE = '@cpq:scroll';

